export default {
    eval(fn) {
        'use strict'
        let Fn = Function
        return new Fn("return " + fn)()
    },
    isMobile() {
        return /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    getLocalStorage(key) {
        let val = localStorage.getItem(key)
        if(key && val) {
            return JSON.parse(val)
        }
    },
    setLocalStorage(key, val) {
        localStorage.setItem(key, JSON.stringify(val))
    },
    removeLocalStorage(key) {
        localStorage.removeItem(key)
    },
    someStartsWith(arr, target) {
        if(!arr || arr.length === 0) {
            return true
        } else {
            let flag = false
            for(let i = 0; i < arr.length; i++) {
                if(target && target.startsWith(arr[i])) {
                    flag = true
                    break
                }
            }
            return flag
        }
    },
    isExistLocal(key, callback) {
        let val = this.getLocalStorage(key)
        if(val && val.length) {
            return callback(val)
        } else {
            return callback()
        }
    }
}
