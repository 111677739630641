<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
@keyframes fly {
  0% {
    background-size: 0 0;
    background-position: -25px -50px;
  }
  100% {
    background-size: 50px 40px;
    background-position: 5px 5px;
  }
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  background: url(./assets/gyps.png) no-repeat;
  background-size: 50px 40px;
  background-position: 5px 5px;
  animation: 3s ease 1 fly;
}
</style>
