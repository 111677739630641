import commonUtil from "@/utils/common-util.js";
export default {
    methods: {
        evalExpression(v, s, k) {
            let l = commonUtil.getLocalStorage(k)
            if(l) {
                if(v === '-') {
                    return false
                } else {
                    return commonUtil.eval(`${v}${l}${s}`)
                }
            } else {
                return true
            }
        },
        /**
         * 范围筛选
         * @param {*} v 值
         * @param {*} t 键
         * @returns 
         */
        rangeFilter(v, t) {
            let l = commonUtil.getLocalStorage(t)
            if(l && l instanceof Array) {
                let f = true
                if(l[0]) {
                    f = f && v >= l[0]
                }
                if(l[1]) {
                    f = f && v <= l[1]
                }
                return f
            } else {
                return true
            }
        }
    }
}