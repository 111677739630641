<template>
  <div class="funds">
    <el-form-item :label="label">
      <el-select
        v-model="funds"
        @change="change"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
<script>
import commonUtil from "@/utils/common-util.js";
export default {
  props: ['label', 'value'],
  data() {
    return {
      funds: commonUtil.getLocalStorage(this.value) || 0,
      options: [
        {
          label: "忽略",
          value: 0
        },
        {
          label: "净流入",
          value: '>'
        },
        {
          label: "净流出",
          value: '<'
        }
      ]
    };
  },
  methods: {
    change(val) {
      commonUtil.setLocalStorage(this.value, val);
    },
    reset() {
      this.funds = 0
      commonUtil.removeLocalStorage(this.value)
    }
  }
};
</script>
