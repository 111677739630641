<template>
  <div class="tree">
    <div id="tree" :style="{ height: height }"></div>
  </div>
</template>
<script>
import external from "@/external.js";
import commonUtil from "@/utils/common-util.js";
import patternUtil from "@/utils/pattern-util.js";
import service from "@/views/tree/service.js";
import "@/static/tree.js";
export default {
  mixins: [service],
  data() {
    return {
      height: 0,
      tree: null,
      isExposed: false,
      timer: null,
      realtimeData: []
    };
  },
  computed: {
    treeData() {
      let weight = commonUtil.getLocalStorage('weight') || 'f20' // 权重
      let codes = commonUtil.getLocalStorage('codes') // 选中的股票

      let obj = this.realtimeData.reduce((acc, curr) => {
        let f100 = curr.f100;
        // 全局过滤
        let condition = true && 
          commonUtil.isExistLocal('plate', res => res ? commonUtil.someStartsWith(res, curr.f12) : true) && // 板块
          commonUtil.isExistLocal('trade', res => res ? res.includes(f100) : true) && // 行业
          commonUtil.isExistLocal('notion', res => res ? res.every(no => curr.f103.includes(no)) : true) // 概念
        
        if(!condition) {
          return acc
        }
        // 计算K线形态
        let kcode = patternUtil.getPattern(curr.f15, curr.f16, curr.f2, curr.f17, curr.f18)

        // 条件过滤
        let filter = true &&
          this.evalExpression(curr.f62, 0, 'funds-main') && // 主力净额
          this.evalExpression(curr.f66, 0, 'funds-oversized') && // 超大单
          this.evalExpression(curr.f72, 0, 'funds-large') && // 大单
          this.evalExpression(curr.f78, 0, 'funds-middle') && // 中单

          this.rangeFilter(curr.f10, 'range-orr') &&  // 量比
          this.rangeFilter(curr.f8, 'range-tr') &&  // 换手率
          this.rangeFilter(curr.f7, 'range-sa') &&  // 振幅
          this.rangeFilter(curr.f34 / curr.f35, 'range-bs') &&  // 外盘/内盘

          this.rangeFilter(curr.f3, 'range-day') &&  // 日涨幅
          this.rangeFilter(curr.f109, 'range-week') &&  // 周涨幅
          this.rangeFilter(curr.f110, 'range-month') &&  // 月涨幅
          this.rangeFilter(curr.f25, 'range-year') &&  // 年涨幅
          
          commonUtil.isExistLocal('kpattern', res => res ? res.includes(kcode) : true) // k线过滤

        filter = commonUtil.isExistLocal('codes', res => res ? res.includes(curr.f12) : filter)

        acc[f100] = acc[f100] || [];
        let element = {
          label: curr.f14,
          filter: filter,
          code: curr.f12,
          zdf: curr.f3 === "-" ? 0 : curr.f3
        }
        if(weight === 'w-active') {
          curr.f62 = curr.f62 === '-' ? 0 : curr.f62
          curr.f10 = curr.f10 === '-' ? 1 : curr.f10
          curr.f21 = curr.f21 === '-' ? 1 : curr.f21
          element.weight = Math.abs(curr.f62 / curr.f21 * curr.f10)
          acc[f100].push(element)
        } else if (curr[weight] !== "-") {
          element.weight = Math.abs(curr[weight])
          acc[f100].push(element)
        }
        return acc;
      }, {});
      let arr = [];
      for (let [key, value] of Object.entries(obj)) {
        if (value.length) {
          let zdf =
            value
              .map(item => item.zdf)
              .reduce((arr, curr) => (curr > 21 ? arr + 10 : arr + curr), 0) /
            value.length;
          arr.push({
            label: key + " " + zdf.toFixed(2) + "%",
            weight: value
              .map(item => item.weight)
              .reduce((arr, curr) => arr + curr, 0),
            filter: true,
            zdf: zdf * 3,
            groups: value
          });
        }
      }
      return arr;
    }
  },
  methods: {
    getRealtimeData() {
      return external
        .getRealTimeData(
          "f2,f3,f6,f7,f8,f10,f12,f14,f15,f16,f17,f18,f20,f21,f24,f25,f26,f34,f35,f62,f66,f72,f78,f84,f100,f103,f109,f110,f127"
        )
        .then(res => {
          if (
            res.status === 200 &&
            res.data &&
            res.data.data &&
            res.data.data.diff
          ) {
            this.realtimeData = res.data.data.diff;
          }
        });
    },
    async updateTree() {
      await this.getRealtimeData()
      this.tree.set({
        rolloutDuration: 0,
        pullbackDuration: 0,
        fadeDuration: 0,
        dataObject: {
          groups: this.treeData,
        }
      })
    },
    initTree() {
      let that = this;
      this.tree = new window.CarrotSearchFoamTree({
        id: "tree",
        layout: "squarified", // 方形布局 默认蜂窝状
        showZeroWeightGroups: "true", // 0权重展示
        groupBorderWidth: 1,
        groupInsetWidth: 1,
        groupStrokeWidth: 1,
        stacking: "flattened", // 扁平化父group
        onGroupDoubleClick: function(e) {
          that.isExposed = !e.secondary;
          e.preventDefault();
          var group = e.secondary
            ? e.bottommostOpenGroup
            : e.topmostClosedGroup;
          var toZoom;
          if (group) {
            this.open({ groups: group, open: !e.secondary });
            toZoom = e.secondary ? group.parent : group;
          } else {
            toZoom = this.get("dataObject");
          }
          this.zoom(toZoom);
        },
        groupLabelLayoutDecorator: function(opts, props, vars) {
          // 自定义父容器label
          if (props.description) {
            vars.verticalPadding = 0.1;
            vars.maxTotalTextHeight = 0.5;
          }
        },
        titleBarDecorator: function(options, parameters, variables) {
          let text =
            parameters.group.label +
            (parameters.group.groups
              ? ""
              : parseFloat(parameters.group.zdf.toFixed(2)) + "%");
          variables.titleBarShown = true;
          variables.titleBarText = text;
        },
        groupBorderRadius: 0,
        groupColorDecorator: this.groupColorDecorator,
        dataObject: {
          groups: this.treeData
        }
      });
    },
    groupColorDecorator(opts, params, vars) {
      let p = params.group.zdf;
      let c =
        params.group.code &&
        (params.group.code.startsWith("30") ||
          params.group.code.startsWith("688"))
          ? 20
          : 10;
      let f = params.group.filter
      if (p === "-" || p === 0 || !f) {
        vars.groupColor.h = 255;
        vars.groupColor.s = 25;
        vars.groupColor.l = 95;
        vars.groupColor.a = 1;
      } else {
        p = p > 0 ? Math.ceil(p) : Math.floor(p); // 向上取整
        p = (p >= c ? c : p <= -c ? -c : p) / c;
        if (p < 0) {
          vars.groupColor.h = 120;
        } else {
          vars.groupColor.h = 0;
        }
        vars.groupColor.s = 50 * (1 + Math.abs(p));
        vars.groupColor.l = 80 * (p > 0 ? Math.abs(1 - p) : Math.abs(1 + p));
        vars.groupColor.a = 1;
      }
      vars.groupColor.model = "hsla";
    }
  },
  async created() {
    if(commonUtil.isMobile()) {
      this.height = window.document.body.clientHeight * 3 + "px"
    } else {
      this.height = window.document.body.clientHeight - 100 + "px";
    }
    await this.getRealtimeData();
    this.initTree();
    this.timer = setInterval(() => {
      if (!this.isExposed) {
        this.updateTree();
      }
    }, 5000);
  },
  destroyed() {
    this.timer = null;
  }
};
</script>
<style lang="scss" scoped>
.tree {
  #tree {
    background: #ddd;
  }
}
</style>
