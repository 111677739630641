<template>
  <div class="trade">
    <el-form-item label="行业">
      <el-select
        v-model="trade"
        multiple
        collapse-tags
        filterable
        placeholder="请选择行业"
        @change="change"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
<script>
import external from "@/external.js";
import commonUtil from "@/utils/common-util.js";
export default {
  data() {
    return {
      trade: commonUtil.getLocalStorage('trade') || [],
      options: []
    };
  },
  methods: {
    change(val) {
      commonUtil.setLocalStorage('trade', val)
    },
    reset() {
      this.trade = []
      commonUtil.removeLocalStorage('trade')
    }
  },
  created() {
    external.getRealTimeData("f100").then(res => {
      Array.from(new Set(res.data.data.diff.map((d) => d.f100))).forEach((item) => {
        this.options.push({ label: item, value: item });
      })
    })
  }
};
</script>
