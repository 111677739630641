export default {
    PERID: {
        DAYS: {
            name: 'DAYS',
            key: 101
        },
        WEEKS: {
            name: 'WEEKS',
            key: 102
        },
        MONTHS: {
            name: 'MONTHS',
            key: 103
        }
    },
    KP: {
        "一字阴线": 100,
        "一字阳线": 101,
        "阴十字星": 200,
        "阳十字星": 201,
        "阴倒T字线": 202,
        "阳倒T字线": 203,
        "阴T字线": 204,
        "阳T字线": 205,
        "阴长上影十字星": 206,
        "阳长上影十字星": 207,
        "阴长下影十字星": 208,
        "阳长下影十字星": 209,
        "光头光脚大阴线": 300,
        "光头光脚大阳线": 301,
        "光头下影大阴线": 302,
        "光头下影大阳线": 303,
        "光脚上影大阴线": 304,
        "光脚上影大阳线": 305,
        "上下影大阴线": 306,
        "上下影大阳线": 307,
        "上影大阴线": 308,
        "上影大阳线": 309,
        "下影大阴线": 310,
        "下影大阳线": 311,
        "光头长下影中阴线": 400,
        "光头长下影中阳线": 401,
        "光脚长上影中阴线": 402,
        "光脚长上影中阳线": 403,
        "上下影中阴线": 404,
        "上下影中阳线": 405,
        "长上影中阴线": 406,
        "长上影中阳线": 407,
        "长下影中阴线": 408,
        "长下影中阳线": 409,
        "光头长下影小阴线": 500,
        "光头长下影小阳线": 501,
        "光脚长上影小阴线": 502,
        "光脚长上影小阳线": 503,
        "上下影小阴线": 504,
        "上下影小阳线": 505,
        "长上影小阴线": 506,
        "长上影小阳线": 507,
        "长下影小阴线": 508,
        "长下影小阳线": 509,
    }
}
