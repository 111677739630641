<template>
  <div class="market-quotes">
    <div class="line">
      <strong>上证：</strong>
      <strong :class="[sh.f3 > 0 ? 'c-red' : 'c-green']">{{ sh.f2 }}</strong>
      <strong :class="[sh.f3 > 0 ? 'c-red' : 'c-green']">{{ sh.f3 }}%</strong>
      <strong>上涨：<span class="c-red">{{ sh.f104 }}</span></strong>
      <strong>平盘：{{ sh.f106 }}</strong>
      <strong>下跌：<span class="c-green">{{ sh.f105 }}</span></strong>
      <strong>成交额：{{ sh.f6 | money }}</strong>
      <strong>主力资金：<span :class="[shFunds > 0 ? 'c-red' : 'c-green']">{{ shFunds | money }}</span></strong>
      <strong>北向资金：<span :class="[hk2sh > 0 ? 'c-red' : 'c-green']">{{ hk2sh | money }}</span></strong>
    </div>
    <div class="line">
      <strong>深证：</strong>
      <strong :class="[sz.f3 > 0 ? 'c-red' : 'c-green']">{{ sz.f2 }}</strong>
      <strong :class="[sz.f3 > 0 ? 'c-red' : 'c-green']">{{ sz.f3 }}%</strong>
      <strong>上涨：<span class="c-red">{{ sz.f104 }}</span></strong>
      <strong>平盘：{{ sz.f106 }}</strong>
      <strong>下跌：<span class="c-green">{{ sz.f105 }}</span></strong>
      <strong>成交额：{{ sz.f6 | money }}</strong>
      <strong>主力资金：<span :class="[szFunds > 0 ? 'c-red' : 'c-green']">{{ szFunds | money }}</span></strong>
      <strong>北向资金：<span :class="[hk2sz > 0 ? 'c-red' : 'c-green']">{{ hk2sz | money }}</span></strong>
    </div>
  </div>
</template>
<script>
import external from "@/external.js";
export default {
  data() {
    return {
      timer: null,
      sh: {},
      sz: {},
      shFunds: 0,
      szFunds: 0,
      hk2sh: 0,
      hk2sz: 0
    };
  },
  methods: {
    async initMarket() {
      await external.getMarketQuotes().then(res => {
        let qqgsData = {};
        eval(`${res.data}`);
        let {
          data: {
            diff: [sh, sz]
          }
        } = qqgsData;
        this.sh = sh;
        this.sz = sz;
      });
      await external.getMarketFunds(1, '000001').then(res => {
        let {
          data: {
            data: {
              f137: f137
            }
          }
        } = res
        this.shFunds = f137
      });
      await external.getMarketFunds(0, '399001').then(res => {
        let {
          data: {
            data: {
              f137: f137
            }
          }
        } = res
        this.szFunds = f137
      });
      external.getHKFunds().then(res => {
        let hsgtData = {}
        eval(`${res.data}`);
        let {
          data: {
            hk2sh: {
              dayNetAmtIn: hk2sh
            },
            hk2sz: {
              dayNetAmtIn: hk2sz
            }
          }
        } = hsgtData
        this.hk2sh = hk2sh * 10000
        this.hk2sz = hk2sz * 10000
      })
    }
  },
  created() {
    this.initMarket();
    this.timer = setInterval(() => {
      this.initMarket();
    }, 5000);
  },
  destroyed() {
    this.timer = null;
  }
};
</script>
<style lang="scss" scoped>
.market-quotes {
  font-size: 14px;
  color: #888;
  .line {
    strong {
      display: inline-block;
      margin: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.c-red,
      span.c-red {
        color: #ff0000;
      }
      &.c-green,
      span.c-green {
        color: #34a835;
      }
      &:nth-child(1),
      &:nth-child(3) {
          width: 45px;
      }
      &:nth-child(2) {
          width: 65px;
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
          width: 80px;
      }
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
          width: 130px;
      }
    }
  }
}
</style>
