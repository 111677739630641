import Vue from 'vue'
Vue.filter('money', value => {
    if (!value || value === '-') {
        return '--'
    }
    let k = 10000, sizes = ['', '万', '亿', '万亿'], i, res;
    if ((value > 0 && value < k) || (value < 0 && Math.abs(value) < k)) {
        return value + '';
    } else {
        i = Math.floor(Math.log(Math.abs(value)) / Math.log(k));
        res = ((Math.abs(value) / Math.pow(k, i))).toFixed(2) + sizes[i]
        return value > 0 ? res : '-' + res;
    }
})
