export default {
    run(arr) {
        const params = [0, 1, 2, 3, 4, 5];
        return arr.map((item, index) => {
            let zdf = {}
            params.forEach(p => {
                if(p === 0) {
                    zdf[p] = item.zdf
                } else if(arr[index + p]) {
                    zdf[p] = parseFloat((((arr[index + p].close / item.close) - 1) * 100).toFixed(2))
                }
            })
            return {
                date: item.date,
                zdf: zdf
            }
        })
    }
}
