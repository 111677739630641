<template>
  <div class="range">
    <el-form-item :label="label">
      <el-row>
        <el-col :span="10">
          <el-input-number
            @change="change('end')"
            :controls="false"
            v-model="start"
            :precision="precision"
            :step="step"
          ></el-input-number>
        </el-col>
        <el-col :span="2" class="text-center">-</el-col>
        <el-col :span="10">
          <el-input-number
            @change="change('start')"
            :controls="false"
            v-model="end"
            :precision="precision"
            :step="step"
          ></el-input-number>
        </el-col>
        <el-col :span="2" class="text-center">{{unit}}</el-col>
      </el-row>
    </el-form-item>
  </div>
</template>
<script>
import commonUtil from "@/utils/common-util.js";
export default {
  props: ["label", "value", "precision", "step", "initial", "unit"],
  data() {
    let local = commonUtil.getLocalStorage(this.value)
    if(local) {
      local = local.map(item => {
        return item === null ? undefined : item
      })
    }
    return {
      start: local ? local[0] : this.initial ? this.initial[0] : undefined,
      end: local ? local[1] : this.initial ? this.initial[1] : undefined
    };
  },
  methods: {
    change(key) {
      if(this.start > this.end) {
        this.$nextTick(() => {
          this[key] = undefined
          commonUtil.setLocalStorage(this.value, [this.start, this.end]);
        })
      }
      commonUtil.setLocalStorage(this.value, [this.start, this.end]);
    },
    reset() {
      this.start = undefined
      this.end = undefined
      commonUtil.removeLocalStorage(this.value)
    }
  },
  created() {
    !commonUtil.getLocalStorage(this.value) && this.initial ? commonUtil.setLocalStorage(this.value, this.initial) : null;
  }
};
</script>
