import external from '@/external.js'
export default {
    methods: {
        /**
         * 获取上证交易数据 用于取最后一个交易日 周 月
         * @returns 
         */
        getSZTransactions(perid) {
            return external.getTransactionData(1, '000001', perid.key, '20210101').then(res => {
                if (res.status === 200 && res.data && res.data.data && res.data.data.klines) {
                    res.data.data.klines.pop()
                    let last = res.data.data.klines.pop()
                    return last.split(',')[0].split(' ')[0].replace(/-/g, '')
                }
            })
        },
        async checkAdd(perid) {
            perid.lastTime = await this.getSZTransactions(perid)
            this[perid.name].keys().then(keys => {
                if (keys.length) {
                    let co = this.allCodes.filter(item => !keys.includes(item.f12))
                    if (co.length) {
                        console.log(`${perid.name} Transaction data add start!`)
                        this.addTransactions(co, perid)
                    } else {
                        // 检查交易数据是否是最新的，如果不是，获取最新交易数据并更新仓库
                        this.checkUpdate(perid)
                    }
                } else {
                    console.log(`${perid.name} Transaction data add start!`)
                    this.addTransactions(this.allCodes, perid)
                }
            })
        },
        addTransactions(codes, perid) {
            let code = codes.pop()
            if (code && perid.lastTime) {
                setTimeout(() => {
                    external.getTransactionData(code.f13, code.f12, perid.key, 0, perid.lastTime).then(d => {
                        if (d.status === 200 && d.data && d.data.data && d.data.data.klines) {
                            let arr = d.data.data.klines
                            this[perid.name].setItem(code.f12, arr).then(() => {
                                this.addTransactions(codes, perid)
                            })
                        } else {
                            this.addTransactions(codes, perid)
                        }
                    }).catch(() => {
                        console.error(`${code.f12} ${perid.name} data add failed!`)
                    })
                })
            } else {
                console.log(`${perid.name} Transaction data add successfully!`)
                // 检查交易数据是否是最新的，如果不是，获取最新交易数据并更新仓库
                this.checkUpdate(perid)
            }
        },
        checkUpdate(perid) {
            let updateArr = []
            let that = this
            this[perid.name].iterate((value, key) => {
                let length = value.length
                if (length) {
                    let preTime = this.$moment(new Date(value[value.length - 1].split(',')[0])).format('YYYYMMDD')
                    if (perid.lastTime > parseInt(preTime)) {
                        let beg = preTime - 0 + 1
                        let codes = this.allCodes.filter(item => item.f12 === key)
                        let code = codes[0]
                        if (code) {
                            updateArr.push({ code, beg })
                        } else {
                            this[perid.name].removeItem(key).then(() => {
                                console.log(`${key} ${perid.name} data remove successfully!`)
                            })
                        }
                    }
                } else {
                    let codes = this.allCodes.filter(item => item.f12 === key)
                    updateArr.push({
                        code: codes[0],
                        beg: 0
                    })
                }
            }).then(function () {
                console.log(`${perid.name} Transaction data update start!`)
                that.updateTransactions(updateArr, perid)
            }).catch(function (err) {
                console.log(err);
            })
        },
        updateTransactions(updateArr, perid) {
            let obj = updateArr.pop()
            if (obj && obj.code && perid.lastTime) {
                setTimeout(() => {
                    external.getTransactionData(obj.code.f13, obj.code.f12, perid.key, obj.beg, perid.lastTime).then(d => {
                        if (d.status === 200 && d.data && d.data.data && d.data.data.klines) {
                            let arr = d.data.data.klines
                            this[perid.name].getItem(obj.code.f12).then(value => {
                                Array.prototype.push.apply(value, arr)
                                this[perid.name].setItem(obj.code.f12, value).then(() => {
                                    this.updateTransactions(updateArr, perid)
                                })
                            })
                        } else {
                            this.updateTransactions(updateArr, perid)
                        }
                    }).catch(() => {
                        console.error(`${obj.code.f12} ${perid.name} data update failed!`)
                    })
                })
            } else {
                console.log(`${perid.name} Transaction data update successfully!`)
            }
        },
    }
}
