export default {
    transactionParse(arr) {
        return arr.map(a => {
            let [
                date, // 日期
                open, // 开盘价
                close, // 收盘价
                high, // 最高价
                low, // 最低价
                count, // 成交量
                amount, // 成交额
                zf, // 振幅
                zdf, // 涨跌幅
                zde, // 涨跌额
                hsl, // 换手率
            ] = a.split(',')
            return {
                date: date,
                open: parseFloat(open),
                close: parseFloat(close),
                high: parseFloat(high),
                low: parseFloat(low),
                count: parseFloat(count),
                amount: parseFloat(amount),
                zf: parseFloat(zf),
                zdf: parseFloat(zdf),
                zde: parseFloat(zde),
                hsl: parseFloat(hsl)
            }
        })
    }
}