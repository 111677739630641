<template>
  <div class="notion">
    <el-form-item label="概念">
      <el-select
        v-model="notion"
        multiple
        collapse-tags
        filterable
        placeholder="请选择概念"
        @change="change"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
<script>
import external from "@/external.js";
import commonUtil from "@/utils/common-util.js";
export default {
  data() {
    return {
      notion: commonUtil.getLocalStorage('notion') || [],
      options: []
    };
  },
  methods: {
    change(val) {
      commonUtil.setLocalStorage('notion', val)
    },
    reset() {
      this.notion = []
      commonUtil.removeLocalStorage('notion')
    }
  },
  created() {
    external.getRealTimeData("f103").then(res => {
      Array.from(new Set([].concat.apply([], res.data.data.diff.map((d) => d.f103.split(','))))).forEach((item) => {
        this.options.push({ label: item, value: item });
      })
    })
  }
};
</script>
