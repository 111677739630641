export default {
  calcHnLn(arr = []) {
    let hn = Number.MIN_SAFE_INTEGER;
    let ln = Number.MAX_SAFE_INTEGER;
    arr.forEach((data) => {
      hn = Math.max(data.high, hn);
      ln = Math.min(data.low, ln);
    })
    return { hn, ln };
  }
}
