<template>
  <div class="home">
    <div class="header">
      <div class="title">JIU 财</div>
      <div class="hidden-md-and-down market">
        <market-quotes></market-quotes>
      </div>
      <div class="oper">
        <el-select
          v-model="codes"
          size="mini"
          class="code-select"
          multiple
          collapse-tags
          filterable
          placeholder="股票代码或名称"
          @change="change"
        >
          <el-option-group
            v-for="group in codeOptions"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
        <i class="el-icon-setting" @click="drawer = true"></i>
        <el-drawer
          title="参数设置"
          :visible.sync="drawer"
          :destroy-on-close="true"
          size="80%"
          :before-close="handleClose"
        >
          <select-overview></select-overview>
        </el-drawer>
      </div>
    </div>
    <div class="main">
      <tree-overview></tree-overview>
    </div>
    <div class="footer">
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import external from "@/external.js";
import commonUtil from "@/utils/common-util.js";
import constUtil from "@/utils/const-util.js";
import getTransactionService from "@/views/get-transaction-service.js";
import setIndicatorsService from "@/views/set-indicators-service.js";
import marketQuotes from "@/views/market/overview.vue";
import selectOverview from "@/views/select/overview.vue";
import treeOverview from "@/views/tree/overview.vue";
import vFooter from "@/views/footer.vue";
export default {
  name: "home",
  mixins: [getTransactionService, setIndicatorsService],
  components: {
    marketQuotes,
    selectOverview,
    treeOverview,
    vFooter,
  },
  data() {
    return {
      allCodes: [],
      codes: commonUtil.getLocalStorage('codes') || [],
      codeOptions: [],
      drawer: false, // 是否显示配置搜索抽屉
    };
  },
  methods: {
    change(val) {
      commonUtil.setLocalStorage('codes', val)
    },
    getAllCodes() {
      return external.getRealTimeData("f12,f13,f14,f100").then((res) => {
        if (
          res.status === 200 &&
          res.data &&
          res.data.data &&
          res.data.data.diff
        ) {
          this.allCodes = res.data.data.diff.map(item => {
            return {
              f12: item.f12,
              f13: item.f13
            }
          })
          res.data.data.diff.forEach((item) => {
            let index = this.codeOptions.findIndex(
              (fi) => fi.label === item.f100
            );
            if (index !== -1) {
              this.codeOptions[index].options.push({
                label: `${item.f12}-${item.f14}`,
                value: item.f12,
              });
            } else {
              this.codeOptions.push({
                label: item.f100,
                options: [
                  { label: `${item.f12}-${item.f14}`, value: item.f12 },
                ],
              });
            }
          });
        }
      });
    },

    handleClose(done) {
      done();
    },
  },
  async created() {
    // 获取所有的股票编码名称所属板块
    await this.getAllCodes();
    // 检查是否存在本地仓库，如果有，检查是否有遗漏的数据未获取；如果没有，获取所有交易数据并存储
    // this.checkAdd(constUtil.PERID.DAYS);
    // this.checkAdd(constUtil.PERID.WEEKS);
    // this.checkAdd(constUtil.PERID.MONTHS);
    // this.createIndicator('wr', constUtil.PERID.DAYS);
    // this.createIndicator('zdf', constUtil.PERID.DAYS);
  },
};
</script>
<style lang="scss" scoped src="./home.scss"></style>
