export default {
  run(arr) {
    let closes = arr.map(item => item.close);
    let params = [5, 10, 22, 66, 144, 255];
    params.forEach((p) => {
      let maP = closes.length > p && this.calculateMA(p, closes);
      arr = arr.map((a, i) => {
        a["ma"] ? null : (a["ma"] = {});
        a["ma"]["ma" + p] = (maP && maP[i]) || "-";
        return a;
      });
    });
    return arr;
  },
  calculateMA(dayCount, closes) {
    let result = [];
    for (let i = 0, len = closes.length; i < len; i++) {
      if (i < dayCount) {
        result.push("-");
        continue;
      }
      let sum = 0;
      for (let j = 0; j < dayCount; j++) {
        sum += closes[i - j];
      }
      result.push(parseFloat((sum / dayCount).toFixed(2)));
    }
    return result;
  }
};
