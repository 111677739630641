<template>
  <div class="weight">
    <el-form-item label="权重">
      <el-select
        v-model="weight"
        placeholder="请选择权重"
        @change="change"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
<script>
import commonUtil from "@/utils/common-util.js";
export default {
  data() {
    return {
      weight: commonUtil.getLocalStorage('weight') || 'f20',
      options: [
        {
          label: "总市值",
          value: "f20",
        },
        {
          label: "成交额",
          value: "f6",
        },
        {
          label: "主力净额",
          value: "f62",
        },
        {
          label: "股价",
          value: "f2",
        },
        {
          label: "活跃度",
          value: "w-active",
        }
      ],
    };
  },
  methods: {
    change(val) {
      commonUtil.setLocalStorage('weight', val)
    },
    reset() {
      this.weight = 'f20'
      commonUtil.removeLocalStorage('weight')
    }
  }
};
</script>
