<template>
  <div class="overview">
    <el-form size="small" label-width="80px">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <plate ref="plate"></plate>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <trade ref="trade"></trade>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <notion ref="notion"></notion>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <weight ref="weight"></weight>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <funds label="主力资金" value="funds-main" ref="funds-main"></funds>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <funds label="超大单" value="funds-oversized" ref="funds-oversized"></funds>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <funds label="大单" value="funds-large" ref="funds-large"></funds>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <funds label="中单" value="funds-middle" ref="funds-middle"></funds>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <range
            label="量比"
            unit="倍"
            value="range-orr"
            :precision="1"
            :step="0.5"
            ref="range-orr"
          ></range>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <range
            label="换手率"
            unit="%"
            value="range-tr"
            :precision="0"
            :step="1"
            ref="range-tr"
          ></range>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <range
            label="振幅"
            unit="%"
            value="range-sa"
            :precision="0"
            :step="1"
            ref="range-sa"
          ></range>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <range
            label="外/内盘"
            unit="倍"
            value="range-bs"
            :precision="1"
            :step="0.5"
            ref="range-bs"
          ></range>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <range
            label="日涨跌幅"
            unit="%"
            value="range-day"
            :precision="0"
            :step="1"
            ref="range-day"
          ></range>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <range
            label="周涨跌幅"
            unit="%"
            value="range-week"
            :precision="0"
            :step="1"
            ref="range-week"
          ></range>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <range
            label="月涨跌幅"
            unit="%"
            value="range-month"
            :precision="0"
            :step="1"
            ref="range-month"
          ></range>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <range
            label="年涨跌幅"
            unit="%"
            value="range-year"
            :precision="0"
            :step="1"
            ref="range-year"
          ></range>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
          <kpattern ref="kpattern"></kpattern>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6">
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="">
            <el-button type="primary" icon="el-icon-delete" size="mini" @click="reset">重置条件</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import plate from "@/views/select/plate.vue";
import trade from "@/views/select/trade.vue";
import notion from "@/views/select/notion.vue";
import weight from "@/views/select/weight.vue";
import funds from "@/views/select/funds.vue";
import range from "@/views/select/range.vue";
import kpattern from "@/views/select/kpattern.vue";
export default {
  components: {
    plate,
    trade,
    notion,
    weight,
    funds,
    range,
    kpattern,
  },
  methods: {
    reset() {
      if(this.$refs) {
        for(let r in this.$refs) {
          this.$refs[r].reset()
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.overview {
  padding: 10px;
}
</style>
