import parseUtil from "@/utils/parse-util.js";
import indicatorUtil from "@/utils/indicator-util.js";
export default {
    methods: {
        getTable(name) {
            return this.$localforage.createInstance({
                name: 'gyps',
                storeName: name
            })
        },
        parseTable(perid) {
            let parsedData = []
            return this[perid.name].iterate((value, key) => {
                if (value.length) {
                    parsedData.push({
                        key,
                        value: parseUtil.transactionParse(value)
                    })
                }
            }).then(() => {
                return parsedData
            })
        },
        writeTable(table, data, callback) {
            let obj = data.pop()
            if(obj) {
                setTimeout(() => {
                    table.setItem(obj.key, obj.value).then(() => {
                        this.writeTable(table, data, callback)
                    })
                })
            } else {
                callback()
            }
        },
        async createIndicator(type, perid) {
            let indicator = this.getTable(`indicators-${type}-${perid.name.toLocaleLowerCase()}`)
            await indicator.clear()
            console.time(type)
            let parsedData = await this.parseTable(perid)
            parsedData = parsedData.map(item => {
                item.value = indicatorUtil[type].run(item.value)
                return item
            })
            console.log(`${perid.name} ${type} data create start!`)
            this.writeTable(indicator, parsedData, () => {
                console.log(`${perid.name} ${type} data create end!`)
                console.timeEnd(type)
            })
        },
        
    }
}
