<template>
  <div class="plate">
    <el-form-item label="板块">
      <el-select
        v-model="plate"
        multiple
        collapse-tags
        placeholder="请选择板块"
        @change="change"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
<script>
import commonUtil from "@/utils/common-util.js";
export default {
  data() {
    return {
      plate: commonUtil.getLocalStorage('plate') || [],
      options: [
        {
          label: "沪A",
          value: "60",
        },
        {
          label: "深A",
          value: "000",
        },
        {
          label: "中小板",
          value: "002",
        },
        {
          label: "创业板",
          value: "30",
        },
        {
          label: "科创板",
          value: "688",
        }
      ],
    };
  },
  methods: {
    change(val) {
      commonUtil.setLocalStorage('plate', val)
    },
    reset() {
      this.plate = []
      commonUtil.removeLocalStorage('plate')
    }
  }
};
</script>
