import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'
export default {
    /**
     * 根据需要的列返回实时交易数据
     * @param {*} fields 
     * @returns 
     */
    getRealTimeData(fields) {
        return axios.get(`https://push2.eastmoney.com/api/qt/clist/get?pn=1&pz=8000&po=1&np=1&ut=b2884a393a59ad64002292a3e90d46a5&fltt=2&invt=2&fid0=f4001&fid=f62&fs=m:0+t:6+f:!2,m:0+t:13+f:!2,m:0+t:80+f:!2,m:1+t:2+f:!2,m:1+t:23+f:!2,m:0+t:7+f:!2,m:1+t:3+f:!2&stat=1&fields=${fields}&rt=53114414&_=1593425974103`)
    },
    /**
     * 根据交易所编码 股票代码 周期编码 查询交易数据
     * @param {*} f13 1：沪市 0：深市
     * @param {*} f12 股票代码
     * @param {*} perid 60：小时 101：日 102：周 103：月
     * @param {*} beg 开始时间
     * @param {*} end 结束时间
     * @returns 
     */
    getTransactionData(f13, f12, perid, beg = 0, end = 20500000) {
        return axios({
            url: `https://push2his.eastmoney.com/api/qt/stock/kline/get?fields1=f1,f2,f3,f4,f5,f6&fields2=f51,f52,f53,f54,f55,f56,f57,f58,f59,f60,f61&ut=7eea3edcaed734bea9cbfc24409ed989&klt=${perid}&fqt=1&secid=${f13}.${f12}&beg=${beg}&end=${end}&_=1604042669988`,
            adapter: jsonpAdapter,
            callbackParamName: 'cb'
        })
    },
    /**
     * 获取大盘行情
     * @returns 
     */
    getMarketQuotes() {
        return axios.get(`https://push2.eastmoney.com/api/qt/ulist.np/get?fid=f3&pi=0&pz=20&po=1&ut=bd1d9ddb04089700cf9c27f6f7426281&fltt=2&fields=f2,f3,f4,f6,f104,f105,f106&np=1&cb=qqgsData=&secids=1.000001,0.399001`)
    },
    /**
     * 获取大盘资金流
     * @param {*} num 1：沪市 0：深市
     * @param {*} code 市场代码
     * @returns 
     */
    getMarketFunds(num, code) {
        return axios({
            url: `https://push2.eastmoney.com/api/qt/stock/get?secid=${num}.${code}&ut=bd1d9ddb04089700cf9c27f6f7426281&fields=f57,f58,f59,f107,f43,f169,f170,f135,f136,f137,f193,f138,f139,f140,f194,f141,f142,f143,f195,f144,f145,f146,f196,f147,f148,f149,f197&invt=2&_=1634051237436`,
            adapter: jsonpAdapter,
            callbackParamName: 'cb'
        })
    },
    /**
     * 获取北上资金流
     */
    getHKFunds() {
        return axios.get(`https://push2.eastmoney.com/api/qt/kamt/get?fields1=f1,f2,f3,f4&fields2=f51,f52,f53,f54&ut=b2884a393a59ad64002292a3e90d46a5&cb=hsgtData=&rt=0.2565141317262616`)
    }
}
