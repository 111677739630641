import common from '@/indicators/common.js'
export default {
  run(arr) {
    const params = [10];
    return arr.map((kline, i) => {
      let close = kline.close;
      let wr = {};
      params.forEach((param) => {
        const p = param - 1;
        if (i >= p) {
          const hln = common.calcHnLn(arr.slice(i - p, i + 1));
          const hn = hln.hn;
          const ln = hln.ln;
          const hnSubLn = hn - ln;
          wr[param] =
            hnSubLn === 0
              ? 0
              : Math.floor(((hn - close) / hnSubLn) * 100 * 100) / 100;
        }
      })
      return {
        date: kline.date,
        wr
      }
    })
  }
}
