import Vue from 'vue'
import localforage from 'localforage'
Vue.prototype.$localforage = localforage

// 日交易数据表
Vue.prototype.DAYS = localforage.createInstance({
    name: 'gyps',
    storeName: 'days'
})
// 周交易数据表
Vue.prototype.WEEKS = localforage.createInstance({
    name: 'gyps',
    storeName: 'weeks'
})
// 月交易数据表
Vue.prototype.MONTHS = localforage.createInstance({
    name: 'gyps',
    storeName: 'months'
})
