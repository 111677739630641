import constUtil from '@/utils/const-util.js'
export default {
    /**
     * 获取K线形态
     * @param {*} max 最高
     * @param {*} min 最低
     * @param {*} cur 最新
     * @param {*} open 开盘
     */
    getPattern(max, min, cur, open) {
        const body = Math.abs(cur - open) // 实体
        const up = max - Math.max(cur, open) // 上影
        const down = Math.min(cur, open) - min // 下影
        const amp = max - min // 振幅
        const base = open > cur ? '阴线' : '阳线'
        let result = ''
        if(amp === 0) {
            result = '一字' + base
        } else {
            const b_p = body / amp // 实体占比
            const u_p = up / amp // 上影占比
            const d_p = down / amp // 下影占比
            if(b_p >= 0.6) {  // 大阴/阳线
                result = this.analyzeTheShadow(u_p, d_p, false) + '大' + base
            } else if(b_p >= 0.4) { // 中阴/阳线
                result = this.analyzeTheShadow(u_p, d_p, true) + '中' + base
            } else if(b_p >= 0.2) { // 小阴/阳线
                result = this.analyzeTheShadow(u_p, d_p, true) + '小' + base
            } else {
                if(u_p === 0) {
                    result = (open > cur ? '阴' : '阳') + 'T字线'
                } else if(d_p === 0) {
                    result = (open > cur ? '阴' : '阳') + '倒T字线'
                } else {
                    const p = Math.round(Math.max(u_p, d_p) / Math.min(u_p, d_p))
                    if(p === 1) {
                        result = (open > cur ? '阴' : '阳') + '十字星'
                    } else if (p > 1 && u_p > d_p) {
                        result = (open > cur ? '阴' : '阳') + '长上影十字星'
                    } else if (p > 1 && u_p < d_p) {
                        result = (open > cur ? '阴' : '阳') + '长下影十字星'
                    }
                }
            }
        }
        return constUtil.KP[result]
    },
    /**
     * 分析影线
     * @param {*} u_p 上影线
     * @param {*} d_p 下影线
     * @param {*} islong 是否带长字
     * @returns 
     */
    analyzeTheShadow(u_p, d_p, islong) {
        if(u_p === 0 && d_p === 0) {
            return '光头' + '光脚'
        } else if(u_p === 0) {
            return '光头' + (islong ? '长' : '') + '下影'
        } else if(d_p === 0) {
            return '光脚' + (islong ? '长' : '') + '上影'
        } else {
            const p = Math.round(Math.max(u_p, d_p) / Math.min(u_p, d_p))
            if(p === 1) {
                return '上下影'
            } else if (p > 1 && u_p > d_p) {
                return (islong ? '长' : '') + '上影'
            } else if (p > 1 && u_p < d_p) {
                return (islong ? '长' : '') + '下影'
            }
        }
    }
}
