<template>
  <div class="kpattern">
    <el-form-item label="K线形态">
      <el-select
        v-model="kpattern"
        multiple
        collapse-tags
        placeholder="请选择K线形态"
        :popper-append-to-body="false"
        @change="change"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
          <span class="k-icon" :class="['k-' + item.value]"></span>
          <span>{{ item.label }}</span>
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
<script>
import commonUtil from "@/utils/common-util.js";
import constUtil from "@/utils/const-util.js";
export default {
  data() {
    return {
      kpattern: commonUtil.getLocalStorage("kpattern") || [],
      options: this.initOptions(),
    };
  },
  methods: {
    change(val) {
      commonUtil.setLocalStorage("kpattern", val);
    },
    initOptions() {
      let kp = constUtil.KP;
      let keys = Object.keys(kp);
      let options = [];
      keys.forEach((k) => {
        options.push({
          label: k,
          value: kp[k],
        });
      });
      return options;
    },
    reset() {
      this.kpattern = [];
      commonUtil.removeLocalStorage("kpattern");
    },
  },
};
</script>
<style lang="scss" scoped>
.kpattern {
  .k-icon {
    background-image: url(../../assets/k-icon.png);
    background-repeat: no-repeat;
    width: 20px;
    height: 34px;
    float: left;
    margin-left: -20px;
    margin-right: 5px;
  }
  .k-100 {
    background-position: -10px -8px;
  }
  .k-101 {
    background-position: -60px -8px;
  }
  .k-200 {
    background-position: -110px -8px;
  }
  .k-201 {
    background-position: -160px -8px;
  }
  .k-202 {
    background-position: -210px -8px;
  }
  .k-203 {
    background-position: -260px -8px;
  }
  .k-204 {
    background-position: -310px -8px;
  }
  .k-205 {
    background-position: -360px -8px;
  }
  .k-206 {
    background-position: -410px -8px;
  }
  .k-207 {
    background-position: -460px -8px;
  }
  .k-208 {
    background-position: -510px -8px;
  }
  .k-209 {
    background-position: -560px -8px;
  }
  .k-300 {
    background-position: -610px -8px;
  }
  .k-301 {
    background-position: -660px -8px;
  }
  .k-302 {
    background-position: -710px -8px;
  }
  .k-303 {
    background-position: -760px -8px;
  }
  .k-304 {
    background-position: -810px -8px;
  }
  .k-305 {
    background-position: -860px -8px;
  }
  .k-306 {
    background-position: -910px -8px;
  }
  .k-307 {
    background-position: -960px -8px;
  }
  .k-308 {
    background-position: -1010px -8px;
  }
  .k-309 {
    background-position: -1060px -8px;
  }
  .k-310 {
    background-position: -1110px -8px;
  }
  .k-311 {
    background-position: -1160px -8px;
  }
  .k-400 {
    background-position: -1210px -8px;
  }
  .k-401 {
    background-position: -1260px -8px;
  }
  .k-402 {
    background-position: -1310px -8px;
  }
  .k-403 {
    background-position: -1360px -8px;
  }
  .k-404 {
    background-position: -1410px -8px;
  }
  .k-405 {
    background-position: -1460px -8px;
  }
  .k-406 {
    background-position: -1510px -8px;
  }
  .k-407 {
    background-position: -1560px -8px;
  }
  .k-408 {
    background-position: -1610px -8px;
  }
  .k-409 {
    background-position: -1660px -8px;
  }
  .k-500 {
    background-position: -1710px -8px;
  }
  .k-501 {
    background-position: -1760px -8px;
  }
  .k-502 {
    background-position: -1810px -8px;
  }
  .k-503 {
    background-position: -1860px -8px;
  }
  .k-504 {
    background-position: -1910px -8px;
  }
  .k-505 {
    background-position: -1960px -8px;
  }
  .k-506 {
    background-position: -2010px -8px;
  }
  .k-507 {
    background-position: -2060px -8px;
  }
  .k-508 {
    background-position: -2110px -8px;
  }
  .k-509 {
    background-position: -2160px -8px;
  }
}
</style>